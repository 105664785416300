/* website: 4201-roco2023
 * created at 2023-08-31 1325:30 by mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota-rocoto.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__toyota-rocoto{
        sup{
            top: 0;
        }
    }
}

